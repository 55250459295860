import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const LogoutButton = () => {
    const navigate = useNavigate();

    const logout = () => {
        const date = new Date();
        localStorage.setItem('token', '');
        localStorage.setItem('is_admin', 'false');
        localStorage.setItem('expiration_date', String(Math.floor(date.getTime() / 1000)));
        navigate('/login', { replace: true });
    };

    return (
        <Button 
            className="btn-box btn-box_large btn-box_midnight-blue" 
            type="button"
            onClick={logout}
        >
            <span>Выйти</span>
        </Button>
    );
};

export default LogoutButton;