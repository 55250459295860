import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import apiRequest from "../api/api";
import { Container, Header, Form as SemanticForm, Message, Button, Grid, Segment } from "semantic-ui-react";

interface LoginProps {
    onLogin: () => void;
}

const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Обязательное поле"),
    password: Yup.string().required("Обязательное поле"),
});

const Login: React.FC<LoginProps> = ({ onLogin }: LoginProps) => {
    const navigate = useNavigate();
    const [error, setError] = useState<boolean>(false);

    const handleLogin = async (values: { username: string; password: string }) => {
        setError(false)
        const date = new Date();
        localStorage.setItem("token", '');
        localStorage.setItem("is_admin", 'false');
        localStorage.setItem("expiration_date", String(Math.floor(date.getTime() / 1000)));
        onLogin();
        const userData = {
            username: values.username,
            password: values.password,
        }
        const result = await apiRequest('/auth/login', navigate, 'POST', userData);
        
        if (result && result.data !== null) {
            localStorage.setItem("token", String(result.access_token));
            localStorage.setItem("is_admin", String(result.is_admin));
            localStorage.setItem("expiration_date", String(result.exiration_date));
            onLogin();
            if (localStorage.getItem('is_admin') === 'true') {
                navigate('/', { replace: true });
            }
            else {
                navigate('/accounts', { replace: true });
            }
        }
        else {
            setError(true);
        }
    };

    return (
        
        <Container>
            <Segment>
                <Grid className="center aligned">
                    <Grid.Column width={5}>
                        <Header as='h1'>Муравейник</Header>
                        <Header as='h3'>Кабинет партнера</Header>
                        <Formik
                            initialValues={{ username: "", password: "" }}
                            validationSchema={LoginSchema}
                            onSubmit={handleLogin}
                        >
                            {({ errors, touched }) => (
                                <Form className="form-box">
                                    <SemanticForm.Field className="form-box__field">
                                        <Field 
                                            className="form-box__input" 
                                            type="text" 
                                            placeholder="Логин"
                                            name="username" 
                                            as={SemanticForm.Input} 
                                            fluid 
                                        />
                                        <ErrorMessage name="username" component={Message} />
                                    </SemanticForm.Field>

                                    <SemanticForm.Field>
                                        <Field 
                                            className="form-box__input" 
                                            type="text" 
                                            name="password" 
                                            placeholder="Пароль"
                                            as={SemanticForm.Input} 
                                            fluid 
                                        />
                                        <ErrorMessage name="password" component={Message} />
                                    </SemanticForm.Field>
                                    {error && (
                                        <Segment>
                                            Учётная запись не найдена
                                        </Segment>
                                    )}
                                    <Segment className="form-box__segment">
                                        <Button type="submit" className="btn-box btn-box_full btn-box_large btn-box_primary btn-box_fill">
                                            <span>Войти</span>
                                        </Button>
                                    </Segment>
                                </Form>
                            )}
                        </Formik>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Container>
    );
};

export default Login;
