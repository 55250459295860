import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../auth/LoginPage';
import ListPartners from '../partners/ListPartners';
import FormPartners from '../partners/FormPartners';
import ListAccounts from '../accounts/ListAccounts';
import FormAccounts from '../accounts/FormAccounts';

interface RouteConfig {
    path: string;
    componentElement: React.ReactNode;
    isPrivate: boolean;
    onlyAdmin: boolean;
}

const RouterControl = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(localStorage.getItem('token') !== null);
    const [isAdmin, setIsAdmin] = useState<boolean>(localStorage.getItem('is_admin') === 'true');
    
    const handleLogin = () => {
        if (localStorage.getItem('is_admin') === 'true') {
            setIsAdmin(true)
        }
        if (localStorage.getItem('token') !== null) {
            setIsLoggedIn(true)
        }
    };

    const routes: RouteConfig[] = [
        {
            path: '/login',
            componentElement: <LoginPage onLogin={handleLogin} />,
            isPrivate: false,
            onlyAdmin: false,
        },
        {
            path: '/',
            componentElement: <ListPartners />,
            isPrivate: true,
            onlyAdmin: true,
        },
        {
            path: '/form',
            componentElement: <FormPartners />,
            isPrivate: true,
            onlyAdmin: true,
        },
        {
            path: '/accounts',
            componentElement: <ListAccounts />,
            isPrivate: true,
            onlyAdmin: false,
        },
        {
            path: '/accounts/form',
            componentElement: <FormAccounts />,
            isPrivate: true,
            onlyAdmin: false,
        },
    ];

    return (
        <BrowserRouter>
            <Routes>
                {routes.map(({ path, componentElement, isPrivate, onlyAdmin }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            (isPrivate && !isLoggedIn) || (onlyAdmin && !isAdmin) ? (
                                ((isLoggedIn && onlyAdmin && !isAdmin) ? <Navigate to="/accounts" /> : <Navigate to="/login" />)
                            ) : (
                                componentElement
                            )
                        }
                    />
                ))}
            </Routes>
        </BrowserRouter>
    );
};

export default RouterControl;