import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FormikHelpers, FormikValues } from "formik";
import { Container, Header, Form as SemanticForm, Message, Grid, Button, Breadcrumb, Segment } from "semantic-ui-react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Aside from "../common/Aside";
import apiRequest from "../api/api";
import { Link } from "react-router-dom";
import LogoutButton from "../auth/LogoutButton";

const FormPartners: React.FC = () => {
    const [partnerData, setPartnerData] = useState({
        manager_name: null,
        manager_phone: null,
        username: null,
        password: null,
        legal_name: null,
        manager_email: null,
        comment: null,
        bonus_percent: null,
    });

    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const partnerId = params.get("id")
    const [activeStatus, setActiveStatus] = useState<boolean | null>(null);

    let validationSchemaData: any = {
        legal_name: Yup.string()
            .nullable()
            .max(50),
        manager_name: Yup.string()
            .required("Обязательное поле")
            .max(50),
        manager_phone: Yup.string()
            .required("Обязательное поле"),
        manager_email: Yup.string()
            .nullable()
            .email("Неверный формат")
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Неверный формат')
            .max(100),
        comment: Yup.string()
            .nullable()
            .max(100),
        username: Yup.string()
            .required("Обязательное поле")
            .matches(/^[a-zA-Z0-9]{8,20}$/, "8-20 символов, только англ. буквы и цифры"),
        bonus_percent: Yup.number()
            .nullable()
            .integer()
            .min(0)
            .max(99)
    };

    if (!partnerId) {
        validationSchemaData = {
            ...validationSchemaData,
            password: Yup.string()
                .required("Обязательное поле")
                .matches(/^[a-zA-Z0-9]{8,20}$/, "8-20 символов, только англ. буквы и цифры")
        }
    }
    const validationSchema = Yup.object().shape(validationSchemaData);

    const handleSubmit = async (values: any, { setSubmitting, setErrors }: FormikHelpers<any>) => {
        let result = null;
        
        Object.keys(values).forEach((key) => {
            if (values[key] !== null && values[key].toString().trim() === '') {
                values[key] = null;
            }
            if (key === 'bonus_percent' && key in values && values[key] !== null) {
                values[key] = parseInt(values[key])
            }
        });
        
        if (partnerId) {
            result = await apiRequest(`/partners/one/${partnerId}`, navigate, 'PUT', values);
        } else {
            result = await apiRequest(`/partners/create`, navigate, 'POST', values);
        }

        if (result && !result.data && !result.message) {
            navigate('/')
        }
        else {
            setSubmitting(false);
        }
        
        if (result.message) {
            setErrors(result.message)
        }
    };

    const handleChangeStatus = async () => {
        const status = await apiRequest(`/partners/status`, navigate, 'POST', {status: !activeStatus, id: partnerId});
        setActiveStatus(status.is_active);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (partnerId) {
                const result = await apiRequest(`/partners/one/${partnerId}`, navigate);
                if (result && result.data) {
                    setPartnerData({...result.data, id: partnerId})
                    if (result.data.is_active) {
                        setActiveStatus(result.data.is_active)
                    }
                }
            }
        };
        fetchData()
    }, [partnerId, navigate]);

    const handleFieldChange = (
        fieldName: keyof FormikValues,
        setFieldValue: (field: any, value: any, shouldValidate?: boolean) => void
    ) => (e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
        const target = e.target as HTMLInputElement;
        if (!target.checkValidity()) {
            e.preventDefault();
            target.value = target.value.substring(0, target.value.length - 1);
            if (target.value.length === 0) {
                setFieldValue(fieldName, '');
            }
        } else {
            setFieldValue(fieldName, value);
        }
    };

    return (
        <Container fluid className='main-box__container-fluid'>
            <Grid columns={2} className='main-box__nomargin'>
                <Aside></Aside>
                <Grid.Column className='grid-box__right'>
                    <Breadcrumb className="bradcrumb-box">
                        <Breadcrumb.Section>
                            <Link to="/">
                                <i className="angle left icon"></i>
                                Вернуться в Партнеры
                            </Link>
                        </Breadcrumb.Section>
                    </Breadcrumb>
                    
                    <div className="grid-box__header">
                        <Header as='h1' className='main-box__title main-box__title_space'>
                            {partnerId ? 'Редактировать партнера' : 'Новый партнер'}
                        </Header>
                        <LogoutButton />
                    </div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={partnerData}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        validateOnChange={true}
                        validateOnBlur={true}
                        validateOnMount={true}
                    >
                        {({ isSubmitting, errors, setFieldValue }) => (
                            <Form className="form-box">
                                <div className="form-box__inner">
                                    <div className="form-box__column">
                                        {partnerId && ( <Field type="hidden" name="id" /> )}
                                        <SemanticForm.Field className="form-box__field">
                                            <label htmlFor="legal_name" className="form-box__label">Юридическое лицо</label>
                                            <Field 
                                                className="form-box__input" 
                                                type="text" 
                                                id="legal_name"
                                                name="legal_name" 
                                                placeholder="1-50 символов" 
                                                as={SemanticForm.Input}
                                                maxLength={50}
                                            />
                                            {errors.legal_name && (<Message>{errors.legal_name}</Message>)}
                                        </SemanticForm.Field>
                                        <SemanticForm.Field className="form-box__field form-box__field_required">
                                            <label htmlFor="legal_name" className="form-box__label">Контактное лицо</label>
                                            <Field 
                                                className="form-box__input" 
                                                type="text" 
                                                name="manager_name" 
                                                placeholder="1-50 символов" 
                                                as={SemanticForm.Input}
                                                required
                                                maxLength={50}
                                            />
                                            {errors.manager_name && (<Message>{errors.manager_name}</Message>)}
                                        </SemanticForm.Field>
                                        <SemanticForm.Field className="form-box__field form-box__field_required">
                                            <label htmlFor="legal_name" className="form-box__label">Телефон</label>
                                            <Field 
                                                className="form-box__input" 
                                                name="manager_phone" 
                                                placeholder="1-32 символов" 
                                                as={SemanticForm.Input}
                                                required
                                                type="text"
                                            />
                                            {errors.manager_phone && (<Message>{errors.manager_phone}</Message>)}
                                        </SemanticForm.Field>
                                        <SemanticForm.Field className="form-box__field">
                                            <label htmlFor="legal_name" className="form-box__label">Почта</label>
                                            <Field 
                                                className="form-box__input" 
                                                type="text" 
                                                name="manager_email" 
                                                placeholder="1-100 символов" 
                                                as={SemanticForm.Input}
                                                maxLength={100}
                                            />
                                            {errors.manager_email && (<Message>{errors.manager_email}</Message>)}
                                        </SemanticForm.Field>
                                        <SemanticForm.Field className="form-box__field">
                                            <label htmlFor="legal_name" className="form-box__label">Комментарий</label>
                                            <Field 
                                                className="form-box__input" 
                                                type="text" 
                                                name="comment" 
                                                placeholder="1-100 символов" 
                                                as={SemanticForm.Input}
                                                maxLength={100}
                                            />
                                            {errors.comment && (<Message>{errors.comment}</Message>)}
                                        </SemanticForm.Field>
                                        <SemanticForm.Field className="form-box__field">
                                            <label htmlFor="legal_name" className="form-box__label">Партнерский процент</label>
                                            <Field 
                                                className="form-box__input" 
                                                type="text" 
                                                name="bonus_percent" 
                                                placeholder="1-2 символов" 
                                                as={SemanticForm.Input}
                                                maxLength={2}
                                                pattern="[0-9]*"
                                                onChange={handleFieldChange('bonus_percent', setFieldValue)}
                                            />
                                            {errors.bonus_percent && (<Message>{errors.bonus_percent}</Message>)}
                                        </SemanticForm.Field>
                                        <SemanticForm.Field className="form-box__field form-box__field_required">
                                            <label htmlFor="legal_name" className="form-box__label">Логин</label>
                                            <Field 
                                                className="form-box__input" 
                                                type="text" name="username" 
                                                placeholder="8-20 символов, только англ. буквы и цифры" 
                                                as={SemanticForm.Input}
                                                maxLength={20}
                                                pattern="^[a-zA-Z0-9]+$"
                                                onChange={handleFieldChange('username', setFieldValue)}
                                                required
                                            />
                                            {errors.username && (<Message>{errors.username}</Message>)}
                                        </SemanticForm.Field>
                                        {!partnerId && (
                                            <SemanticForm.Field className="form-box__field form-box__field_required">
                                                <label htmlFor="legal_name" className="form-box__label">Пароль</label>
                                                <Field 
                                                    className="form-box__input" 
                                                    type="text" name="password" 
                                                    placeholder="8-20 символов, только англ. буквы и цифры" 
                                                    as={SemanticForm.Input}
                                                    maxLength={20}
                                                    pattern="^[a-zA-Z0-9]+$"
                                                    onChange={handleFieldChange('password', setFieldValue)}
                                                    required
                                                />
                                                {errors.password && (<Message>{errors.password}</Message>)}
                                            </SemanticForm.Field>
                                        )}
                                    </div>
                                    <div className="form-box__column" style={{ flexGrow: 0 }}>
                                        {partnerId && (
                                            <SemanticForm.Field className="form-box__field">
                                                <Button 
                                                    className="btn-box btn-box_large btn-box_primary btn-box_fill"
                                                    type="button" 
                                                    onClick={handleChangeStatus}
                                                >
                                                    <span>
                                                        {activeStatus ? 'Заблокировать доступ' : 'Разблокировать доступ'}
                                                    </span>
                                                </Button>
                                            </SemanticForm.Field>
                                        )}
                                    </div>
                                </div>
                                <Segment className="form-box__segment">
                                    <Button 
                                        className="btn-box btn-box_large btn-box_primary btn-box_fill" 
                                        type="submit" 
                                        disabled={isSubmitting}
                                    >
                                        <span>Сохранить</span>
                                    </Button>

                                    <Link 
                                        to="/"
                                        className="btn-box btn-box_large btn-box_primary btn-box_fill btn-box_gray" 
                                    >
                                        <span>Отменить</span>
                                    </Link>
                                </Segment>
                            </Form>
                        )}
                    </Formik>
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default FormPartners;