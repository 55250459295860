import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import './App.sass'
import RouterControl from './router/RouterControl';

function App() {
    return (
        <RouterControl />
    );
}

export default App;