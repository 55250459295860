import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FormikHelpers, FormikValues } from "formik";
import {
    Container,
    Header,
    Form as SemanticForm,
    Message,
    Grid,
    Button,
    Breadcrumb,
    Segment,
    Table,
    Dropdown,
    Pagination,
    Input,
    Icon,
    Popup,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
} from "semantic-ui-react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Aside from "../common/Aside";
import apiRequest from "../api/api";
import { Link } from "react-router-dom";
import moment from "moment";
import sprite from "../svg/sprite.svg";
import LogoutButton from "../auth/LogoutButton";
import { declOfNum } from '../tools/declOfNum';

type Option = {
    key: string;
    value: string;
    text: string;
};

interface AccountBillingHistoryData {
    created_at: Date;
    comment: string;
    paid_amount: number;
}

interface ITariffChange {
    maxPlaceCount: number;
    monthRate: number;
}

interface IAccountData {
    partner_id: string,
    legal_name: string | null,
    inn: number | null,
    manager_name: string | null,
    manager_phone: number | null,
    manager_email: string | null,
    comment: string | null,
    max_place_count: number | null,
    month_rate: number | null,
    auth_key: string | null,
    username: string | null,
    password: string | null,
    balance: number | null,
    status: boolean,
    registration_hash: string | null,
    trial_period_until_date: string,
}

interface IModalData {
    isShowModal: boolean;
    isShowModal2?: boolean
    isModalInfo: boolean;
}

interface IPaymentData {
    sum: string;
    type: {
        key: 'card' | 'bill' | '',
        value: string
    }
    error?: boolean;
}

const CURRENCY: string = '₽';

const FormAccounts: React.FC = () => {
    const [accountData, setAccountData] = useState<IAccountData>({
        partner_id: '',
        legal_name: null,
        inn: null,
        manager_name: null,
        manager_phone: null,
        manager_email: null,
        comment: null,
        max_place_count: null,
        month_rate: 0,
        balance: null,
        auth_key: null,
        username: null,
        password: null,
        status: true,
        registration_hash: null,
        trial_period_until_date: (new Date(Date.now() + 12096e5)).toISOString().slice(0, 10),
    });

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const accountId = params.get("id")
    const isAdmin = localStorage.getItem('is_admin') === 'true';
    const navigate = useNavigate();
    const [partnerFilterOption, setPartnerFilterOption] = useState<Option[]>([]);
    const [status, setStatus] = useState<string>('');
    const [authKey, setAuthKey] = useState<string>('');
    const [activatedAt, setActivatedAt] = useState<Date | null>(null);
    const [trialPeriodUntilDate, setTrialPeriodUntilDate] = useState<Date | null>(null);
    const [tabSelected, setTabSelected] = useState<string>('detail');
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<AccountBillingHistoryData[]>([]);
    const [activePage, setActivePage] = useState<number>(1);
    const [sortKey, setSortKey] = useState<string>('created_at');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [perPage, setPerPage] = useState<number>(50);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [paidUpToDate, setPaidUpToDate] = useState<Date | null>(null);
    const [login, setLogin] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [registrationHash, setRegistrationHash] = useState<string | null>(null);
    const [tariffChange, setTariffChange] = useState<ITariffChange | undefined>(undefined);
    const [modalData, setModalData] = useState<IModalData>({ isShowModal: false, isShowModal2: false, isModalInfo: false });
    const [paymentData, setPaymentData] = useState<IPaymentData>({
        sum: '0',
        type: {
            key: '',
            value: ''
        }
    });
    const [paymentErrors, setPaymentErrors] = useState<string[]>([]);
    const [balanceApplication, setBalanceApplication] = useState<number | undefined>(undefined);

    const paymentTypeOptions = [
        {
            key: 'card',
            text: 'Оплата картой',
            value: 'Оплата картой',
        },
        {
            key: 'bill',
            text: 'Оплата по счету',
            value: 'Оплата по счету',
        },
        {
            key: 'promoCode',
            text: 'Промокод',
            value: 'Промокод',
        }
    ];

    const paymentValidationSchemaData: any = {
        sum: Yup.string().matches(/[1-9]/, 'Введите сумму').max(9, 'Максимум 1 000 000 ₽').required('Введите сумму'),
        type: Yup.object({
            key: Yup.string().nonNullable(),
            value: Yup.string().nonNullable().required('Выберите тип платежа'),
        }),
    };

    let validationSchemaData: any = {
        partner_id: Yup.string().nullable(),
        legal_name: Yup.string()
            .nullable()
            .max(50),
        inn: Yup.string()
            .nullable()
            .matches(/^\d{10}$/, '10 символов, только цифры'),
        manager_name: Yup.string()
            .max(50)
            .matches(/^.+\s.+.*$/, 'Поле должен состоять минимум из двух слов')
            .required('Обязательное поле'),
        manager_phone: Yup.string()
            .max(32)
            .required('Обязательное поле'),
        manager_email: Yup.string()
            .email('Неверный формат')
            .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Неверный формат')
            .max(32)
            .required('Обязательное поле'),
        comment: Yup.string()
            .nullable()
            .max(100),
        max_place_count: Yup.string()
            .matches(/^\d{1,5}$/, '1-5 символов')
            .required('Обязательное поле'),
        month_rate: Yup.string()
            .required('Обязательное поле')
            .matches(/^\d{1,10}$/, '1-10 символов'),
    }

    if (!accountId) {
        validationSchemaData = {
            ...validationSchemaData,
            auth_key: Yup.string()
                .matches(/^[a-zA-Z0-9]{4,20}$/, '4-20 символов, только англ. буквы и цифры')
                .required('Обязательное поле'),
            username: Yup.string()
                .matches(/^[a-zA-Z0-9]{8,20}$/, '8-20 символов, только англ. буквы и цифры')
                .required('Обязательное поле'),
            password: Yup.string()
                .matches(/^[a-zA-Z0-9]{8,20}$/, '8-20 символов, только англ. буквы и цифры')
                .required('Обязательное поле'),
            status: Yup.boolean(),
            trial_period_until_date: Yup.date().nullable()
        }
    }

    const validationSchema = Yup.object().shape(validationSchemaData);

    const paymentValidationSchema = Yup.object(paymentValidationSchemaData)

    useEffect(() => {
        const fetchDataShortList = async () => {
            if (isAdmin) {
                try {
                    const result: { data: Array<{id: string, manager_name: string}> } = await apiRequest('/partners/short-list', navigate);
                    const partnersOptions = result.data.map(({ id, manager_name }) => ({
                        key: id,
                        value: id,
                        text: manager_name,
                    }));
                    setPartnerFilterOption([
                        { key: 'empty', value: '', text: 'Нет партнера' },
                        ...partnersOptions,
                    ]);
                } catch (error) {
                
                }
            }
        };
        fetchDataShortList()

        const fetchData = async () => {
            if (accountId) {
                const result = await apiRequest(`/accounts/one/${accountId}`, navigate);
                if (result && result.data) {
                    if (result.data.status) {
                        setStatus(result.data.status);
                        delete result.data['status'];
                    }
                    if (result.data.auth_key) {
                        setAuthKey(result.data.auth_key);
                        delete result.data['auth_key'];
                    }
                    if (result.data.activated_at) {
                        setActivatedAt(result.data.activated_at);
                        delete result.data['activated_at'];
                    }
                    if (result.data.trial_period_until_date) {
                        setTrialPeriodUntilDate(result.data.trial_period_until_date);
                        delete result.data['trial_period_until_date'];
                    }
                    if (result.data.paid_up_to_date) {
                        setPaidUpToDate(result.data.paid_up_to_date);
                        delete result.data['paid_up_to_date'];
                    }
                    if (result.data.login) {
                        setLogin(result.data.login);
                        delete result.data['login'];
                    }
                    if (result.data.password) {
                        setPassword(result.data.password);
                        delete result.data['password'];
                    }
                    if (result.data.registration_hash) {
                        setRegistrationHash(result.data.registration_hash);
                        delete result.data['registration_hash'];
                    }
                    if (result.data.tariff_change) {
                        setTariffChange(result.data.tariff_change);
                        delete result.data['tariff_change'];
                    }
                    
                    setAccountData({...result.data, id: accountId});
                }
            }
            setLoading(false);
        };
        fetchData()
    }, [accountId, navigate]);


    const handleSubmit = async (values: any, { setSubmitting, setErrors }: FormikHelpers<any>) => {
        let result = null;
        
        Object.keys(values).forEach((key) => {
            if (values[key] !== null && values[key].toString().trim() === '') {
                values[key] = null;
            }
            if ((key === 'max_place_count' || key === 'month_rate') && key in values && values[key] !== null) {
                values[key] = parseInt(values[key]);
            }
        });
        
        if (accountId) {
            result = await apiRequest(`/accounts/one/${accountId}`, navigate, 'PUT', values);
        } else {
            result = await apiRequest(`/accounts/create`, navigate, 'POST', values);
        }

        if (result && !result.data && !result.message) {
            navigate('/accounts');
        }
        else {
            setSubmitting(false);
        }
        
        if (result.message) {
            setErrors(result.message);
        }
    };

    const handleActivateAccount = async () => {
        const result = await apiRequest(`/accounts/activate`, navigate, 'POST', {id: accountId});
        setStatus(result.status);
        setRegistrationHash(result.registration_hash);
        setActivatedAt(result.activated_at);
    }

    const handleSignAsClient = async () => {
        const { resultLink }: { resultLink: string } = await apiRequest(`/auth/sign-as-client`, navigate, 'POST', { id: accountId, currentUrl: window.location.href });
        if (resultLink) {
            return window.location.assign(resultLink);
        } else {
            return console.error('Не удалось войти под клиентом');
        }
    }

    const handleFieldChange = (
        fieldName: keyof FormikValues,
        setFieldValue: (field: any, value: any, shouldValidate?: boolean) => void
    ) => (e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
        const target = e.target as HTMLInputElement;
        if (!target.checkValidity()) {
            e.preventDefault();
            target.value = target.value.substring(0, target.value.length - 1);
            
            if (target.value.length === 0) {
                setFieldValue(fieldName, '');
            }
        } else {
            setFieldValue(fieldName, value);
        }
    };

    const handleChangeTab = (value: string) => {
        setTabSelected(value);
    }

    const handleCopyLink = () => {
        const link: string = `https://lk.mrvk.pro/registration?key=${registrationHash}`
        navigator.clipboard.writeText(link);
    }

    const handleValidateFields = (): void => {
        paymentValidationSchema.validate(paymentData, { abortEarly: false })
            .then(async () => {
                setPaymentErrors([]);
                await createPaymentOrder();
            })
            .catch((err) => {
                setPaymentErrors(err.errors);
            });
    }

    const createPaymentOrder = async () => {
        const payloadData = {
            sum: Number(paymentData.sum.replace(/[\s,]/g, '')),
            type: paymentData.type.value.toLowerCase()
        }
        const result = await apiRequest(`/accounts/${accountId}/change-balance`, navigate, 'POST', payloadData);
        if (result.id) {
            setModalData({ isShowModal: false, isModalInfo: false, isShowModal2: !!result.id });
        } else {
            setModalData({ isShowModal: false, isModalInfo: true, isShowModal2: true });
        }
    }

    const handlePaymentTypeChange = (e: React.SyntheticEvent<HTMLElement>, data: any) => {
        const { value } = data;
        const { key } = data.options.find((o: any) => o.value === value);
        setPaymentErrors([]);
        setPaymentData({ ...paymentData, type: { key, value } });
    }

    const handleSumChange = (e: React.SyntheticEvent<HTMLElement>, value: string) => {
        let res = '0';
         if (value.length && value.match(/[1-9]/g)) {
            res = Number(value
                .replace(/^0+/g,'')
                .replace(/[\D.]/g, ''))
                .toLocaleString('ru-RU');
         }
        setPaymentErrors([]);
        setPaymentData({ ...paymentData, sum: res });
    }

    const handleSendMail = async () => {
        const resolve = await apiRequest(`/mail/send-reg-link`, navigate, 'POST', { id: accountId });
        setModalData( { isShowModal: true, isModalInfo: !!resolve.id });
    }
    useEffect(() => {
        if (accountId) {
            const fetchAccountBillingHistoryData = async () => {
                setLoading(true);
                const result: { data: AccountBillingHistoryData[], pageInfo: { pageCount: number } } = await apiRequest(`/accounts/${accountId}/billing_history/list?page=${activePage-1}:${perPage}&order=${sortKey}:${sortOrder}&search=${searchQuery}`, navigate);
                if (result && result.data) {
                    setData(result.data);
    
                    setTotalPages(result.pageInfo.pageCount);
                    setLoading(false);
                }
            };
    
            fetchAccountBillingHistoryData();
        }
    }, [activePage, sortKey, sortOrder, perPage, searchQuery, accountId, navigate]);

    const handleSort = (sortKeySelected: string) => {
        let newSortOrder: 'asc' | 'desc' = 'desc';
        if (sortKeySelected === sortKey) {
            newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        }
        setSortKey(sortKeySelected);
        setSortOrder(newSortOrder);
        setActivePage(1);
    };

    const handleSortClick = (key: string, isSort: boolean) => {
        if (isSort) {
            handleSort(key);
        }
    }

    const handleToggleTrial = () => {
        setAccountData((prevState) => ({
            ...prevState,
            status: !accountData.status
        }));
    }

    const handleTrialDateChange = (value: string) => {
        setAccountData((prevState) => ({
            ...prevState,
            trial_period_until_date: value
        }));
    }

    const tableHeadData = [
        { key: 'created_at', sort: true, text: 'Дата' },
        { key: 'comment', sort: true, text: 'Основание' },
        { key: 'paid_amount', sort: true, text: 'Сумма' },
    ];

    const tableHead = tableHeadData.map((option) => (
        <Table.HeaderCell key={option.key} onClick={() => handleSortClick(option.key, option.sort)}>
            {option.text}
            {option.sort && sortKey === option.key && (
                sortKey === option.key && sortOrder === 'desc' ? (
                    <i className="caret up icon"></i>
                ) : (
                    <i className="caret down icon"></i>
                )
            )}
        </Table.HeaderCell>
    ));

    const tableRows = data.length > 0 ? (
        data.map((item: AccountBillingHistoryData, index: number) => (
            <Table.Row key={index}>
                <Table.Cell>{moment(item.created_at).format('DD.MM.YYYY')}</Table.Cell>
                <Table.Cell>{item.comment}</Table.Cell>
                <Table.Cell>{item.paid_amount}</Table.Cell>
            </Table.Row>
        ))
    ) : (
        <Table.Row><Table.Cell className="table-box__td_empty" colSpan={3}>Ничего не найдено.</Table.Cell></Table.Row>
    );

    const handleSubmitSearch = (value: string) => {
        setSearchQuery(value);
        setActivePage(1);
    }

    const perPageOptions = [
        { key: '10', text: 'Показывать по 10', value: 10 },
        { key: '20', text: 'Показывать по 20', value: 20 },
        { key: '50', text: 'Показывать по 50', value: 50 },
        { key: '100', text: 'Показывать по 100', value: 100 },
    ];

    const handlePerPageChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        setPerPage(data.value);
        setActivePage(1);
    };

    const handlePageChange = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setActivePage(data.activePage);
    };

    const handleAdminBalanceChange = async () => {
        setLoading(true);
        setModalData({ isModalInfo: false, isShowModal: true })
        const sumChanges: boolean = Number(paymentData.sum) === 0;
        const typeChanges: boolean = paymentData.type.value === ''

        // Проверяем изменения в полях, чтобы запросить текущую заявку
        if (sumChanges && typeChanges) {
            const result: { refill_amount: number } = await apiRequest(`/accounts/balance_application/${accountId}`, navigate);
            if (result.refill_amount) {
                setBalanceApplication(result.refill_amount);
            }
        }
        setLoading(false);
    }

    const now = moment().unix();
    const isValidDate: boolean = moment(trialPeriodUntilDate).unix() > now;

    return (
        <Container fluid className='main-box__container-fluid'>
            <Grid columns={2} className='main-box__nomargin'>
                <Aside></Aside>
                <Grid.Column className='grid-box__right'>
                    <Breadcrumb className="bradcrumb-box">
                        <Breadcrumb.Section>
                            <Link to="/accounts">
                                <i className="angle left icon"></i>
                                Вернуться в Клиенты
                            </Link>
                        </Breadcrumb.Section>
                    </Breadcrumb>
                    <div className="grid-box__header">
                        <Header as='h1' className='main-box__title main-box__title_space'>
                            {accountId ? 'Просмотр клиента' : 'Новый клиент'}
                        </Header>

                        <LogoutButton />
                    </div>
                    <Button
                        type="button"
                        className={`btn-box btn-box_large btn-box_fill btn-box_border-radius-top ${
                            tabSelected === 'detail' ? 'btn-box_white' : 'btn-box_gray'
                        }`}
                        onClick={() => handleChangeTab('detail')}
                    >
                        <span>Данные клиента</span>
                    </Button>
                    <Button
                        type="button"
                        className={`btn-box btn-box_large btn-box_fill btn-box_border-radius-top ${
                            tabSelected === 'payment' ? 'btn-box_white' : 'btn-box_gray'
                        }`}
                        onClick={() => handleChangeTab('payment')}
                    >
                        <span>Оплата</span>
                    </Button>
                    {tabSelected === 'detail' && !loading && (
                        <Formik
                            enableReinitialize={true}
                            initialValues={accountData}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validateOnMount={true}
                        >
                            {({ isSubmitting, errors, setFieldValue }) => (
                                <Form className="form-box form-box_border-radius-rb">
                                    <div className="form-box__inner">
                                        <div className="form-box__column">
                                            {accountId && ( <Field type="hidden" name="id" /> )}
                                            {isAdmin && (
                                                <SemanticForm.Field className="form-box__field">
                                                    <label htmlFor="partner_id" className="form-box__label">Партнер</label>
                                                    <Field 
                                                        className="form-box__input form-box__select form-box__select_full" 
                                                        type="text"
                                                        name="partner_id" 
                                                        id="partner_id"
                                                        value={accountData.partner_id}
                                                        placeholder="Нет партнера" 
                                                        options={partnerFilterOption}
                                                        as={SemanticForm.Select}
                                                        onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                            setFieldValue('partner_id', value);
                                                            setAccountData((prevState) => ({
                                                                ...prevState,
                                                                partner_id: value,
                                                            }));
                                                        }}
                                                    />
                                                    {errors.legal_name && (<Message>{errors.legal_name}</Message>)}
                                                </SemanticForm.Field>
                                            )}
                                            <SemanticForm.Field className="form-box__field">
                                                <label htmlFor="legal_name" className="form-box__label">Юридическое лицо</label>
                                                <Field 
                                                    className="form-box__input" 
                                                    type="text" 
                                                    name="legal_name"
                                                    value={accountData.legal_name}
                                                    placeholder="1-50 символов"
                                                    as={SemanticForm.Input}
                                                    maxLength={50}
                                                    readOnly={status === 'activated'}
                                                    onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                        setAccountData((prevState) => ({
                                                            ...prevState,
                                                            legal_name: value,
                                                        }));
                                                    }}
                                                />
                                                {errors.legal_name && (<Message>{errors.legal_name}</Message>)}
                                            </SemanticForm.Field>
                                            <SemanticForm.Field className="form-box__field">
                                                <label htmlFor="legal_name" className="form-box__label">ИНН</label>
                                                <Field 
                                                    className="form-box__input" 
                                                    type="text" 
                                                    name="inn"
                                                    value={accountData.inn}
                                                    placeholder="10 символов, только цифры" 
                                                    as={SemanticForm.Input}
                                                    maxLength={10}
                                                    readOnly={status === 'activated'}
                                                    onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: number }) => {
                                                        setAccountData((prevState) => ({
                                                            ...prevState,
                                                            inn: value,
                                                        }));
                                                    }}
                                                />
                                                {errors.inn && (<Message>{errors.inn}</Message>)}
                                            </SemanticForm.Field>
                                            <SemanticForm.Field className="form-box__field form-box__field_required">
                                                <label htmlFor="legal_name" className="form-box__label">Контактное лицо</label>
                                                <Field 
                                                    className="form-box__input" 
                                                    type="text" 
                                                    name="manager_name"
                                                    value={accountData.manager_name}
                                                    placeholder="1-50 символов" 
                                                    as={SemanticForm.Input}
                                                    maxLength={50}
                                                    readOnly={status === 'activated'}
                                                    required
                                                    onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                        setAccountData((prevState) => ({
                                                            ...prevState,
                                                            manager_name: value,
                                                        }));
                                                    }}
                                                />
                                                {errors.manager_name && (<Message>{errors.manager_name}</Message>)}
                                            </SemanticForm.Field>
                                            <SemanticForm.Field className="form-box__field form-box__field_required">
                                                <label htmlFor="legal_name" className="form-box__label">Телефон</label>
                                                <Field 
                                                    className="form-box__input" 
                                                    name="manager_phone" 
                                                    placeholder="1-32 символов" 
                                                    as={SemanticForm.Input}
                                                    value={accountData.manager_phone}
                                                    type="text"
                                                    maxLength={32}
                                                    readOnly={status === 'activated'}
                                                    required
                                                    onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: number }) => {
                                                        setAccountData((prevState) => ({
                                                            ...prevState,
                                                            manager_phone: value,
                                                        }));
                                                    }}
                                                />
                                                {errors.manager_phone && (<Message>{errors.manager_phone}</Message>)}
                                            </SemanticForm.Field>
                                            <SemanticForm.Field className="form-box__field form-box__field_required">
                                                <label htmlFor="legal_name" className="form-box__label">Почта</label>
                                                <Field 
                                                    className="form-box__input" 
                                                    type="text" 
                                                    name="manager_email"
                                                    value={accountData.manager_email}
                                                    placeholder="1-32 символов"
                                                    as={SemanticForm.Input}
                                                    maxLength={32}
                                                    readOnly={status === 'activated'}
                                                    required
                                                    onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                        setAccountData((prevState) => ({
                                                            ...prevState,
                                                            manager_email: value,
                                                        }));
                                                    }}
                                                />
                                                {errors.manager_email && (<Message>{errors.manager_email}</Message>)}
                                            </SemanticForm.Field>
                                            {!accountId ? (
                                                <>
                                                    <SemanticForm.Field className="form-box__field form-box__field_required">
                                                        <label htmlFor="legal_name" className="form-box__label">Компания</label>
                                                        <Field 
                                                            className="form-box__input" 
                                                            type="text" 
                                                            name="auth_key"
                                                            value={accountData.auth_key}
                                                            placeholder="4-20 символов, только англ. буквы и цифры" 
                                                            as={SemanticForm.Input}
                                                            pattern="^[a-zA-Z0-9]+$"
                                                            maxLength={20}
                                                            required
                                                            onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                                handleFieldChange('auth_key', setFieldValue);
                                                                setAccountData((prevState) => ({
                                                                    ...prevState,
                                                                    auth_key: value,
                                                                }));
                                                            }}
                                                        />
                                                        {errors.auth_key && (<Message>{errors.auth_key}</Message>)}
                                                    </SemanticForm.Field>
                                                    <SemanticForm.Field className="form-box__field form-box__field_required">
                                                        <label htmlFor="legal_name" className="form-box__label">Логин</label>
                                                        <Field 
                                                            className="form-box__input" 
                                                            type="text" 
                                                            name="username"
                                                            value={accountData.username}
                                                            placeholder="4-20 символов, только англ. буквы и цифры" 
                                                            as={SemanticForm.Input}
                                                            pattern="^[a-zA-Z0-9]+$"
                                                            maxLength={20}
                                                            required
                                                            onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                                handleFieldChange('username', setFieldValue);
                                                                setAccountData((prevState) => ({
                                                                    ...prevState,
                                                                    username: value,
                                                                }));
                                                            }}
                                                        />
                                                        {errors.username && (<Message>{errors.username}</Message>)}
                                                    </SemanticForm.Field>
                                                    <SemanticForm.Field className="form-box__field form-box__field_required">
                                                        <label htmlFor="legal_name" className="form-box__label">Пароль</label>
                                                        <Field 
                                                            className="form-box__input" 
                                                            type="text" 
                                                            name="password"
                                                            value={accountData.password}
                                                            placeholder="4-20 символов, только англ. буквы и цифры" 
                                                            as={SemanticForm.Input}
                                                            pattern="^[a-zA-Z0-9]+$"
                                                            maxLength={20}
                                                            required
                                                            onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                                handleFieldChange('password', setFieldValue);
                                                                setAccountData((prevState) => ({
                                                                    ...prevState,
                                                                    password: value,
                                                                }));
                                                            }}
                                                        />
                                                        {errors.password && (<Message>{errors.password}</Message>)}
                                                    </SemanticForm.Field>
                                                </>
                                            ) : (
                                                <>
                                                    <SemanticForm.Field className="form-box__field">
                                                        <div className="form-box__label">Компания</div>
                                                        <div>{authKey}</div>
                                                    </SemanticForm.Field>
                                                    {status === 'test' ? (
                                                        <>
                                                            <SemanticForm.Field className="form-box__field form-box__field">
                                                                <label htmlFor="legal_name" className="form-box__label">Логин</label>
                                                                <div>{login}</div>
                                                            </SemanticForm.Field>
                                                            <SemanticForm.Field className="form-box__field form-box__field">
                                                                <label htmlFor="legal_name" className="form-box__label">Пароль</label>
                                                                <div>{password}</div>
                                                            </SemanticForm.Field>
                                                        </>
                                                    ) : (
                                                        <SemanticForm.Field className="form-box__field form-box__field_required">
                                                            <label htmlFor="legal_name" className="form-box__label">Логин</label>
                                                            <div>{login}</div>
                                                        </SemanticForm.Field>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="form-box__column">
                                                {!accountId ? (
                                                    <div className="form-box__demo">
                                                        <SemanticForm.Field className="form-box__field">
                                                            <div className="form-box__field-action">
                                                                <Field
                                                                    className="form-box__input"
                                                                    type="checkbox"
                                                                    name="status"
                                                                    as={SemanticForm.Input}
                                                                    onChange={handleToggleTrial}
                                                                />
                                                                <span>Тестововый период до:</span>
                                                            </div>

                                                            {errors.status && (<Message>{errors.status}</Message>)}
                                                        </SemanticForm.Field>
                                                        <SemanticForm.Field className="form-box__field">
                                                            <Field
                                                                className="form-box__input"
                                                                type="date"
                                                                name="trial_period_until_date"
                                                                min={new Date().toISOString().slice(0, 10)}
                                                                as={SemanticForm.Input}
                                                                onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => handleTrialDateChange(value)}
                                                            />
                                                            {errors.trial_period_until_date && (<Message>{errors.trial_period_until_date}</Message>)}
                                                        </SemanticForm.Field>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <div className="client">
                                                            <div className="client_buttons">
                                                                <div className="form-box__field">
                                                                    <div className="register_line">
                                                                        {status === 'registered' ? (
                                                                            <SemanticForm.Field>
                                                                                Клиент зарегистрирован: {moment(activatedAt).format('DD.MM.YYYY')}
                                                                            </SemanticForm.Field>
                                                                        ) : (
                                                                            <SemanticForm.Field>
                                                                                Клиент не зарегистрирован
                                                                            </SemanticForm.Field>
                                                                        )}
                                                                        {status !== 'registered' ? (
                                                                            <Button
                                                                                className="btn-box btn-box_large btn-box_primary btn-box_fill btn-box_right"
                                                                                type="button"
                                                                                disabled={!!registrationHash}
                                                                                onClick={handleActivateAccount}
                                                                            >
                                                                                <span>Зарегистрировать клиента</span>
                                                                            </Button>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <SemanticForm.Field className="form-box__field">
                                                                    <div className='register_line'>
                                                                        {isValidDate ? (
                                                                            <SemanticForm.Field>
                                                                                Тестововый период до: {moment(trialPeriodUntilDate).format('DD.MM.YYYY')}
                                                                            </SemanticForm.Field>
                                                                        ) : null}
                                                                        <Button
                                                                            className="btn-box btn-box_large btn-box_primary btn-box_fill btn-box_right"
                                                                            type="button"
                                                                            onClick={handleSignAsClient}
                                                                        >
                                                                            <span>Войти под клиентом</span>
                                                                        </Button>
                                                                    </div>
                                                                </SemanticForm.Field>
                                                            </div>
                                                            {status !== 'registered' ? (
                                                                 <div className="client_icons">
                                                                <Popup
                                                                    content='Скопировать ссылку'
                                                                    position="top right"
                                                                    basic
                                                                    trigger={<Icon name="copy outline" size="big" disabled={!registrationHash} link={!!registrationHash} onClick={handleCopyLink} />}
                                                                />
                                                                <Popup
                                                                    content="Отправить ссылку на почту"
                                                                    position="top right"
                                                                    basic
                                                                    trigger={<Icon name="mail outline" size="big" disabled={!registrationHash} link={!!registrationHash} onClick={handleSendMail} />}
                                                                />
                                                            </div>
                                                            ) :  null}
                                                        </div>
                                                        {modalData.isShowModal ? (
                                                            <Modal
                                                                open={modalData.isShowModal}
                                                                closeIcon
                                                                onClose={() => setModalData({ isModalInfo: false, isShowModal: false })}
                                                                content={modalData.isModalInfo ? 'Письмо отправлено' : 'Письмо не удалось отправить'}
                                                            />
                                                        ) : null}
                                                    </>
                                                )}
                                            <SemanticForm.Field className="form-box__field form-box__field_required">
                                                <label htmlFor="legal_name" className="form-box__label">Максимум точек</label>
                                                <Field
                                                    className="form-box__input"
                                                    type="text"
                                                    name="max_place_count"
                                                    placeholder="1-5 символов"
                                                    as={SemanticForm.Input}
                                                    pattern="[0-9]*"
                                                    maxLength={5}
                                                    value={accountData.max_place_count}
                                                    disabled={!!tariffChange}
                                                    required
                                                    onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: number }) => {
                                                        handleFieldChange('max_place_count', setFieldValue);
                                                        setAccountData((prevState) => ({
                                                            ...prevState,
                                                            max_place_count: value,
                                                        }));
                                                    }}
                                                />
                                                {!!tariffChange ? null : errors.max_place_count && (<Message>{errors.max_place_count}</Message>)}
                                            </SemanticForm.Field>
                                            <SemanticForm.Field className="form-box__field form-box__field_required" onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => e.key >= '48'}>
                                                <label htmlFor="legal_name" className="form-box__label">Оплата</label>
                                                <Field
                                                    className="form-box__input"
                                                    type="text"
                                                    name="month_rate"
                                                    placeholder="1-10 символов"
                                                    as={SemanticForm.Input}
                                                    disabled={!!tariffChange}
                                                    value={Number(accountData.month_rate?.toString().replace(/[\D.]+/g, '')) || 0}
                                                    pattern="[0-9]*"
                                                    maxLength={10}
                                                    required
                                                    onChange={(e: React.SyntheticEvent<HTMLInputElement>, { value }: { value: number }) => {
                                                        if (value.toString().length !== 10) {
                                                            handleFieldChange('month_rate', setFieldValue);
                                                            setAccountData((prevState) => ({
                                                                ...prevState,
                                                                month_rate: value
                                                            }));
                                                        }
                                                    }}
                                                />
                                                {!!tariffChange ? null : errors.month_rate && (<Message>{errors.month_rate}</Message>)}
                                                {!!tariffChange && (<Message>{`Выполняется заявка на изменение тарифа: ${tariffChange.maxPlaceCount} ${declOfNum(tariffChange.maxPlaceCount, ['точка', 'точки', 'точек'])}, ${tariffChange.monthRate} руб/мес.`}</Message>)}
                                            </SemanticForm.Field>
                                            <SemanticForm.Field className="form-box__field">
                                                <label htmlFor="legal_name" className="form-box__label">Комментарий</label>
                                                <Field
                                                    className="form-box__input"
                                                    type="text"
                                                    name="comment"
                                                    placeholder="1-100 символов"
                                                    maxLength={100}
                                                    value={accountData.comment}
                                                    as={SemanticForm.Input}
                                                    onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                        setAccountData((prevState) => ({
                                                            ...prevState,
                                                            comment: value,
                                                        }));
                                                    }}
                                                />
                                                {errors.comment && (<Message>{errors.comment}</Message>)}
                                            </SemanticForm.Field>
                                        </div>
                                    </div>
                                    <Segment className="form-box__segment">
                                        <Button 
                                            className="btn-box btn-box_large btn-box_primary btn-box_fill" 
                                            type="submit" 
                                            disabled={!!tariffChange || isSubmitting}
                                        >
                                            <span>Сохранить</span>
                                        </Button>

                                        <Link 
                                            to="/accounts"
                                            className="btn-box btn-box_large btn-box_primary btn-box_fill btn-box_gray" 
                                        >
                                            <span>Отменить</span>
                                        </Link>
                                    </Segment>
                                </Form>
                            )}
                        </Formik>
                    )}
                    {tabSelected === 'payment' && !loading && (
                        <>
                            <Container fluid className='main-box__content main-box__content_border-radius-rb'>
                                <Grid className="grid-box__area">
                                    <Grid.Column width={10} className="grid-box__area">
                                        <span>Баланс: {accountData.balance || 0} {CURRENCY} Оплачено до: {paidUpToDate && (moment(paidUpToDate).format('DD.MM.YYYY'))}</span>
                                    </Grid.Column>
                                    {accountId && (
                                        <Grid.Column width={6} className="grid-box__area right">
                                            {isAdmin ? (
                                                <Button className="grid-box__payment-btn" size="small" onClick={handleAdminBalanceChange}>
                                                    <span>Ручное пополнение</span>
                                                </Button>
                                            ) : null}
                                            <SemanticForm className='grid-box__action-search'>
                                                <Input 
                                                    icon="search"
                                                    placeholder='Поиск...' 
                                                    onChange={(event) => handleSubmitSearch(event.target.value)} 
                                                />
                                            </SemanticForm>
                                        </Grid.Column>
                                    )}
                                </Grid>
                                {accountId && (
                                    <div className='grid-box__space grid-box__space_small'>
                                        <Table celled className='table-box'>
                                            <Table.Header>
                                                <Table.Row>
                                                    {tableHead}
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {tableRows}
                                            </Table.Body>
                                        </Table>
                                        {loading && 'Загрузка...'}
                                    </div>
                                )}
                            </Container>
                            {accountId && (
                                <Container fluid>
                                    <Grid.Row className='pagination-box'>
                                        <Grid.Column className='pagination-box__col'>
                                            <Dropdown
                                                options={perPageOptions}
                                                text={`Показывать по ${perPage}`}
                                                value={perPage}
                                                onChange={handlePerPageChange}
                                                className="pagination-box__dropdown"
                                            />
                                            {totalPages > 0 && (
                                                <Pagination
                                                    totalPages={totalPages}
                                                    activePage={activePage}
                                                    onPageChange={handlePageChange}
                                                    className="pagination-box__pages"
                                                    prevItem={{ content: <svg className='btn-box_icon-left-arrow'><use xlinkHref={`${sprite}#left-arrow`}></use></svg> }}
                                                    nextItem={{ content: <svg className='btn-box_icon-right-arrow'><use xlinkHref={`${sprite}#right-arrow`}></use></svg> }}
                                                />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Container>
                            )}
                            {modalData.isShowModal ? (
                                <Modal
                                    open={modalData.isShowModal}
                                    closeIcon
                                    onClose={() => {
                                        setModalData({ isModalInfo: false, isShowModal: false })
                                        setBalanceApplication(undefined);
                                    }}
                                >
                                    <ModalHeader>Ручное пополнение</ModalHeader>
                                    <ModalContent>
                                        <div className="form-box form-box_border-radius-rb">
                                            <div className="form-box__inner">
                                                <div className="form-box__column">
                                                    <div className="form-box__payment form-box__payment-field">
                                                        <label className="form-box__label">Сумма:</label>
                                                        <Input
                                                            className="form-box__payment-input"
                                                            name="sum"
                                                            placeholder="0"
                                                            value={paymentData.sum}
                                                            type="text"
                                                            required
                                                            disabled={!!balanceApplication}
                                                            maxLength={9}
                                                            autoFocus
                                                            onChange={(e: React.SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
                                                                handleSumChange(e, value)
                                                            }}
                                                        />
                                                        <span>{CURRENCY}</span>
                                                    </div>
                                                    <div className="form-box__payment form-box__payment-field">
                                                        <label className="form-box__label">Тип оплаты:</label>
                                                        <Dropdown
                                                            className="form-box__payment-dropdown"
                                                            placeholder="Выберите тип платежа"
                                                            selection
                                                            required
                                                            disabled={!!balanceApplication}
                                                            value={paymentData.type.value}
                                                            options={paymentTypeOptions}
                                                            onChange={(e: React.SyntheticEvent<HTMLElement>, data: any) => {
                                                                handlePaymentTypeChange(e, data)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {paymentErrors.length > 0 ? (
                                            <div className="form-box__payment-error">
                                                {paymentErrors.map((err: string) => (
                                                    <div key={err}>
                                                        {err}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                        {balanceApplication ? (
                                            <div className="form-box__payment-info">
                                                <Message>
                                                    Выполняется заявка на изменение баланса: {balanceApplication.toLocaleString('ru-RU')} {CURRENCY}
                                                </Message>
                                            </div>
                                        ) : null}
                                    </ModalContent>
                                    <ModalActions>
                                        <Button
                                            className="btn-box btn-box_large btn-box_primary btn-box_fill"
                                            type="submit"
                                            disabled={!!paymentErrors.length || !!balanceApplication}
                                            onClick={handleValidateFields}
                                        >
                                            <span>Сохранить</span>
                                        </Button>
                                        <Button
                                            className="btn-box btn-box_large btn-box_primary btn-box_fill btn-box_gray"
                                            type="submit"
                                            onClick={() => setModalData({ isModalInfo: false, isShowModal: false })}
                                        >
                                            <span>Отмена</span>
                                        </Button>
                                    </ModalActions>
                                </Modal>
                                ) : null}
                            {modalData.isShowModal2 ? (
                                <Modal
                                    open={modalData.isShowModal2}
                                    closeIcon
                                    onClose={() => {
                                        setModalData({ isModalInfo: false, isShowModal: false, isShowModal2: false })
                                        setPaymentData({ sum: '0', type: { key: '', value: '' }})
                                    }}
                                    content={modalData.isModalInfo ? 'Не удалось создать заявку на пополнение баланса' : 'Создана заявка на пополнение баланса'}
                                />
                            ) : null}
                        </>
                    )}
                </Grid.Column>
            </Grid>
        </Container>
    );
};

export default FormAccounts;