import { NavigateFunction } from "react-router-dom";

const apiUrl = '/api';

export default async function apiRequest(
    url: string,
    navigate: NavigateFunction,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    body?: Record<string, any>,
): Promise<any> {
    if (window.location.pathname !== '/login') {
        const date = new Date();
        if (localStorage.getItem("expiration_date") !== null && localStorage.getItem("expiration_date") !== 'undefined') {
            if (parseInt(String(localStorage.getItem("expiration_date"))) < Math.floor(date.getTime() / 1000)) {
                navigate("/login");
            }
        } else {
            navigate("/login");
        }
    }
    
    const options: RequestInit = {
        method,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    if (localStorage.getItem('token')) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        };
    }

    if (body) {
        options.body = JSON.stringify(body);
    }

    const response = await fetch(`${apiUrl}${url}`, options);
    if (response.status === 403) {
        navigate("/login");
    }
    if (!response.ok && response.status !== 400) {
        return {data: null}
    }

    try {
        return await response.json();
    }
    catch {
        return {data: null}
    }
}
