import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Pagination, Dropdown, Grid, Input, Form, Container, Header, Checkbox, Button, Modal } from 'semantic-ui-react';
import apiRequest from '../api/api';
import Aside from '../common/Aside';
import sprite from '../svg/sprite.svg';
import LogoutButton from '../auth/LogoutButton';

interface CountData {
    [key: string]: number;
}

interface UserData {
    id: string;
    legal_name: string;
    manager_name: string;
    manager_phone: string;
    comment: string;
    _count: CountData;
    bonus_percent: number;
}

const ListPartners = () => {
    const [data, setData] = useState<UserData[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [activePage, setActivePage] = useState<number>(1);
    const [sortKey, setSortKey] = useState<string>('created_at');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [perPage, setPerPage] = useState<number>(50);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [dataVersion, setDataVersion] = useState<number>(0);
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const navigate = useNavigate();
    
    const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>, data: any) => {
        const { value, checked } = data
        setCheckedAll(false);
        if (checked) {
            setSelectedIds([...selectedIds, value])
        } else {
            setSelectedIds(selectedIds.filter(item => item !== value))
        }
    }

    const handleCheckboxChangeAll = (event: React.FormEvent<HTMLInputElement>, dataCheckbox: any) => {
        const { checked } = dataCheckbox;
        
        if (checked) {
            const allIds = data.map((user: UserData) => user.id);
            setSelectedIds(allIds);
        } else {
            setSelectedIds([]);
        }
        setCheckedAll(checked);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const result: { data: UserData[], pageInfo: { pageCount: number } } = await apiRequest(`/partners/list?page=${activePage-1}:${perPage}&order=${sortKey}:${sortOrder}&search=${searchQuery}`, navigate);
            if (result && result.data) {
                setSelectedIds([]);
                setCheckedAll(false);

                setData(result.data);

                setTotalPages(result.pageInfo.pageCount);
                setLoading(false);
            }
        };

        fetchData();
    }, [activePage, sortKey, sortOrder, perPage, dataVersion, searchQuery, navigate]);

    const handleSort = (sortKeySelected: string) => {
        let newSortOrder: 'asc' | 'desc' = 'desc';
        if (sortKeySelected === sortKey) {
            newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        }
        setSortKey(sortKeySelected);
        setSortOrder(newSortOrder);
        setActivePage(1);
    };

    const generateEditLink = (id: string) => {
        return `/form?id=${id}`
    }

    const tableRows = data.length > 0 ? (
        data.map((item: UserData) => (
            <Table.Row key={item.id} className={selectedIds.includes(item.id) ? 'table-box__row-selected' : ''}>
                <Table.Cell>
                    <Checkbox checked={selectedIds.includes(item.id)} value={item.id} onChange={handleCheckboxChange}/>
                </Table.Cell>
                <Table.Cell><Link to={generateEditLink(item.id)}>{item.legal_name}</Link></Table.Cell>
                <Table.Cell><Link to={generateEditLink(item.id)}>{item.manager_name}</Link></Table.Cell>
                <Table.Cell><Link to={generateEditLink(item.id)}>{item.manager_phone}</Link></Table.Cell>
                <Table.Cell><Link to={generateEditLink(item.id)}>{item.comment}</Link></Table.Cell>
                <Table.Cell><Link to={generateEditLink(item.id)}>{item._count.account}</Link></Table.Cell>
                <Table.Cell><Link to={generateEditLink(item.id)}>{item.bonus_percent}</Link></Table.Cell>
            </Table.Row>
        ))
    ) : (
        <Table.Row><Table.Cell className="table-box__td_empty" colSpan={7}>Ничего не найдено.</Table.Cell></Table.Row>
    );

    const handlePageChange = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setActivePage(data.activePage);
    };

    const handlePerPageChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        setPerPage(data.value);
        setActivePage(1);
    };

    const tableHeadData = [
        { key: 'checkbox', sort: false, text: <Checkbox checked={checkedAll} onChange={handleCheckboxChangeAll}/> },
        { key: 'legal_name', sort: true, text: 'Юр. лицо' },
        { key: 'manager_name', sort: true, text: 'Контактное лицо' },
        { key: 'manager_phone', sort: true, text: 'Телефон' },
        { key: 'comment', sort: true, text: 'Комментарий' },
        { key: 'count', sort: true, text: 'Клиентов' },
        { key: 'bonus_percent', sort: true, text: '%' },
    ];

    const handleSortClick = (key: string, isSort: boolean) => {
        if (isSort) {
            handleSort(key)
        }
    }

    const tableHead = tableHeadData.map((option) => (
        <Table.HeaderCell key={option.key} onClick={() => handleSortClick(option.key, option.sort)}>
            {option.text}
            {option.sort && sortKey === option.key && (
                sortKey === option.key && sortOrder === 'desc' ? (
                    <i className="caret up icon"></i>
                ) : (
                    <i className="caret down icon"></i>
                )
            )}
        </Table.HeaderCell>
    ));

    const perPageOptions = [
        { key: '10', text: 'Показывать по 10', value: 10 },
        { key: '20', text: 'Показывать по 20', value: 20 },
        { key: '50', text: 'Показывать по 50', value: 50 },
        { key: '100', text: 'Показывать по 100', value: 100 },
    ];

    const handleSubmit = (value: string) => {
        setSearchQuery(value)
        setActivePage(1);
    }

    const handleDelete = async () => {
        setOpenModal(false)
        await apiRequest(`/partners?ids=${selectedIds.join(",")}`, navigate, 'DELETE');
        setSelectedIds([])
        setDataVersion(dataVersion + 1);
    }

    const openDeleteModal = () => {
        setOpenModal(true)
    }

    return (
        <>
            <Container fluid className='main-box__container-fluid'>
                <Grid columns={2} className='main-box__nomargin'>
                    <Aside></Aside>
                    <Grid.Column className='grid-box__right'>
                        <div className="grid-box__header">
                            <Header as='h1' id='header-block' className='main-box__title'>Партнеры</Header>
                            <LogoutButton />
                        </div>
                        <Container fluid className='main-box__content'>
                            <Grid className="grid-box__area" id='actions-block'>
                                <Grid.Column width={10} className="grid-box__area">
                                    <Link 
                                        to="/form" 
                                        className='grid-box__action-btn btn-box btn-box_primary btn-box_icon btn-box_fill btn-box_icon-bg'
                                    >
                                        <div className='btn-box_icon-bg-area'>
                                            <svg className='btn-box_icon-plus'><use xlinkHref={`${sprite}#plus`}></use></svg>
                                        </div>
                                        <span>Добавить</span>
                                    </Link>
                                    
                                        <Button 
                                            disabled={selectedIds.length <= 0}
                                            onClick={openDeleteModal}
                                            className="grid-box__action-btn btn-box btn-box_simple btn-box_icon btn-box_primary-color"
                                        >
                                            <div className='btn-box_simple-icon'>
                                                <svg className='btn-box_icon-trash'><use xlinkHref={`${sprite}#trash`}></use></svg>
                                            </div>
                                            <span>Удалить выбранные ({selectedIds.length})</span>
                                        </Button>
                                </Grid.Column>
                                <Grid.Column className='grid-box__search'>
                                    <Form className='grid-box__action-search'>
                                        <Input 
                                            icon="search"
                                            placeholder='Поиск...' 
                                            onChange={(event) => handleSubmit(event.target.value)} 
                                        />
                                    </Form>
                                </Grid.Column>
                            </Grid>
                            <div className='grid-box__space'>
                                <Table celled className='table-box'>
                                    <Table.Header>
                                        <Table.Row>
                                            {tableHead}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {tableRows}
                                    </Table.Body>
                                </Table>
                            </div>
                            {loading && 'Загрузка...'}
                        </Container>
                        <Container fluid id="pagination-block">
                            <Grid.Row className='pagination-box'>
                                <Grid.Column className='pagination-box__col'>
                                    <Dropdown
                                        options={perPageOptions}
                                        text={`Показывать по ${perPage}`}
                                        value={perPage}
                                        onChange={handlePerPageChange}
                                        className="pagination-box__dropdown"
                                    />
                                    {totalPages > 0 && (
                                        <Pagination
                                            totalPages={totalPages}
                                            activePage={activePage}
                                            onPageChange={handlePageChange}
                                            className="pagination-box__pages"
                                            prevItem={{ content: <svg className='btn-box_icon-left-arrow'><use xlinkHref={`${sprite}#left-arrow`}></use></svg> }}
                                            nextItem={{ content: <svg className='btn-box_icon-right-arrow'><use xlinkHref={`${sprite}#right-arrow`}></use></svg> }}
                                        />
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Container>
                    </Grid.Column>
                </Grid>
            </Container>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Вы уверены что хотите удалить партнера?</Modal.Header>
                <Modal.Content>
                    Удалить?
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleDelete}>Да</Button>
                    <Button onClick={() => setOpenModal(false)}>Нет</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ListPartners;