import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Grid } from 'semantic-ui-react';
import sprite from '../svg/sprite.svg';

const Aside: React.FC = () => {
    return (
         <Grid.Column width={2} className='aside-box'>
            <Menu vertical className='aside-box__menu'>
                <Menu.Item className='aside-box__logo'>
                    <NavLink to={localStorage.getItem('is_admin') === 'true' ? '/' : '/accounts'}>
                        <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="Logo" />
                    </NavLink>
                </Menu.Item>
                {localStorage.getItem('is_admin') === 'true' && (
                    <Menu.Item className='menu-box__item'>
                        <NavLink to='/' className='menu-box__link'>
                            <svg><use xlinkHref={`${sprite}#users`}></use></svg>
                            <span>Партнеры</span>
                        </NavLink>
                    </Menu.Item>
                )}
                <Menu.Item className='menu-box__item'>
                    <NavLink to='/accounts' className='menu-box__link'>
                        <svg><use xlinkHref={`${sprite}#users`}></use></svg>
                        <span>Клиенты</span>
                    </NavLink>
                </Menu.Item>
            </Menu>
        </Grid.Column>
    );
};

export default Aside;