import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Pagination, Dropdown, Grid, Input, Form, Container, Header, Checkbox, Button, Modal, Icon } from 'semantic-ui-react';
import apiRequest from '../api/api';
import Aside from '../common/Aside';
import sprite from '../svg/sprite.svg';
import moment from 'moment';
import LogoutButton from '../auth/LogoutButton';

interface CountData {
    [key: string]: number;
}

interface NameData {
    [key: string]: string;
}

interface UserData {
    created_at: Date;
    id: string;
    legal_name: string;
    manager_name: string;
    manager_phone: string;
    comment: string;
    _count: CountData;
    partner: NameData;
    paid_up_to_date: Date | null
    status: string | null;
    activated_at: Date | null;
    trial_period_until_date: Date | null;
    max_place_count: number;
    month_rate: number | null;
}

type Option = {
    key: string;
    value: string;
    text: string;
    checked: boolean
};

const ListAccounts = () => {
    const [selectedPartnerFilterOptions, setSelectedPartnerFilterOptions] = useState<string[]>([]);
    const [partnerFilterOption, setPartnerFilterOption] = useState<Option[]>([]);
    const [data, setData] = useState<UserData[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [dataVersion, setDataVersion] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [activePage, setActivePage] = useState<number>(1);
    const [sortKey, setSortKey] = useState<string>('created_at');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [loading, setLoading] = useState<boolean>(false);
    const [perPage, setPerPage] = useState<number>(50);
    const navigate = useNavigate();
    const [checkedAll, setCheckedAll] = useState<boolean>(false);
    const isAdmin = localStorage.getItem('is_admin') === 'true';

    useEffect(() => {
        const fetchDataShortList = async () => {
            if (isAdmin) {
                try {
                    const result: { data: Array<{id: string, manager_name: string}> } = await apiRequest('/partners/short-list', navigate);
                    const partnersOptions = result.data.map(({ id, manager_name }) => ({
                        key: id,
                        value: id,
                        text: manager_name,
                        checked: false
                    }));
                    setPartnerFilterOption(partnersOptions);
                } catch (error) {
                
                }
            }
            
        };
        fetchDataShortList()
    }, [isAdmin, navigate]);
    
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const result: { data: UserData[], pageInfo: { pageCount: number } } = await apiRequest(`/accounts/list?page=${activePage-1}:${perPage}&order=${sortKey}:${sortOrder}&search=${searchQuery}&partner_ids=${selectedPartnerFilterOptions}`, navigate);
            if (result && result.data) {
                setSelectedIds([]);
                setCheckedAll(false);
                setData(result.data);

                setTotalPages(result.pageInfo.pageCount);
                setLoading(false);
            }
        };

        fetchData();
    }, [activePage, sortKey, sortOrder, perPage, dataVersion, searchQuery, selectedPartnerFilterOptions, navigate]);

    const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>, data: any) => {
        const { value, checked } = data
        setCheckedAll(false);
        if (checked) {
            setSelectedIds([...selectedIds, value])
        } else {
            setSelectedIds(selectedIds.filter(item => item !== value))
        }
    }

    const handleCheckboxChangeAll = (event: React.FormEvent<HTMLInputElement>, dataCheckbox: any) => {
        const { checked } = dataCheckbox;
        
        if (checked) {
            const allIds = data.map((user: UserData) => user.id);
            setSelectedIds(allIds);
        } else {
            setSelectedIds([]);
        }
        setCheckedAll(checked);
    };

    const handleSort = (sortKeySelected: string) => {
        let newSortOrder: 'asc' | 'desc' = 'desc';
        if (sortKeySelected === sortKey) {
            newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
        }
        setSortKey(sortKeySelected);
        setSortOrder(newSortOrder);
        setActivePage(1);
    };

    const handleSortClick = (key: string, isSort: boolean) => {
        if (isSort) {
            handleSort(key)
        }
    }

    const tableHeadData = [
        { key: 'checkbox', is_admin: true, sort: false, text: <Checkbox checked={checkedAll} onChange={handleCheckboxChangeAll}/> },
        { key: 'created_at', is_admin: false, sort: true, text: 'Создано' },
        { key: 'legal_name', is_admin: false, sort: true, text: 'Юр. лицо' },
        { key: 'manager_name', is_admin: false, sort: true, text: 'Контактное лицо' },
        { key: 'manager_phone', is_admin: false, sort: true, text: 'Телефон' },
        { key: 'comment', is_admin: false, sort: true, text: 'Комментарий' },
        { key: 'employees', is_admin: false, sort: true, text: 'Сотрудников' },
        { key: 'max_place_count', is_admin: false, sort: true, text: 'Точек' },
        { key: 'month_rate', is_admin: false, sort: true, text: 'Оплата' },
        { key: 'partner', is_admin: false, sort: false, text: 'Партнер' },
        { key: 'paid_date', is_admin: false, sort: false, text: 'Оплачено' },
    ];

    const tableHead = tableHeadData.map((option) => (
        <>
            {((isAdmin && option.is_admin) || !option.is_admin) && (
                <Table.HeaderCell key={option.key} onClick={() => handleSortClick(option.key, option.sort)}>
                    {option.text}
                    {option.sort && sortKey === option.key && (
                        sortKey === option.key && sortOrder === 'desc' ? (
                            <i className="caret up icon"></i>
                        ) : (
                            <i className="caret down icon"></i>
                        )
                    )}
                </Table.HeaderCell>
            )}
        </>
    ));

    const generateEditLink = (id: string) => {
        return `/accounts/form?id=${id}`
    }
    const nowDate = moment().unix();
    const endDayByUnix: number = 86399;
    const tableRows = data.length > 0 ? (
        data.map((item: UserData) => {
            const trialPeriodDate = moment(item.trial_period_until_date).unix();
            const paidUpToDate = moment(item.paid_up_to_date).unix();
            return (
                <Table.Row key={item.id} className={selectedIds.includes(item.id) ? 'table-box__row-selected' : ''}>
                    {isAdmin && (
                        <Table.Cell>
                            <Checkbox checked={selectedIds.includes(item.id)} value={item.id} onChange={handleCheckboxChange}/>
                        </Table.Cell>
                    )}
                    <Table.Cell><Link to={generateEditLink(item.id)}>{moment(item.created_at).format('DD.MM.YYYY')}</Link></Table.Cell>
                    <Table.Cell><Link to={generateEditLink(item.id)}>{item.legal_name}</Link></Table.Cell>
                    <Table.Cell><Link to={generateEditLink(item.id)}>{item.manager_name}</Link></Table.Cell>
                    <Table.Cell><Link to={generateEditLink(item.id)}>{item.manager_phone}</Link></Table.Cell>
                    <Table.Cell><Link to={generateEditLink(item.id)}>{item.comment}</Link></Table.Cell>
                    <Table.Cell><Link to={generateEditLink(item.id)}>{item._count.user}</Link></Table.Cell>
                    <Table.Cell><Link to={generateEditLink(item.id)}>{item.max_place_count}</Link></Table.Cell>
                    <Table.Cell><Link to={generateEditLink(item.id)}>{item.month_rate}</Link></Table.Cell>
                    <Table.Cell>
                        <Link to={generateEditLink(item.id)}>
                            {item.partner ? (item.partner.manager_name) : ('Нет партнера')}
                        </Link>
                    </Table.Cell>
                    <Table.Cell>
                        {(item.paid_up_to_date || item.trial_period_until_date) && (
                            <Link to={generateEditLink(item.id)}>
                                {item.status && ['test', 'registered', 'activated'].includes(item.status) ? (
                                    trialPeriodDate > nowDate ? (
                                        <>
                                            <div>Тест до</div>
                                            <div>{moment(item.trial_period_until_date).format('DD.MM.YYYY')}</div>
                                        </>
                                    ) : item.paid_up_to_date && paidUpToDate + endDayByUnix > nowDate ? (
                                        <>
                                            <div>Оплата до</div>
                                            <div>{moment(item.paid_up_to_date).format('DD.MM.YYYY')}</div>
                                        </>
                                        ) : (
                                            <>
                                                {/* Аккаунт заблокирован */ }
                                                {item.paid_up_to_date ? (
                                                    <>
                                                        <div>Оплачено до</div>
                                                        <div>{moment(item.paid_up_to_date).format('DD.MM.YYYY')}</div>
                                                    </>
                                                ) : item.trial_period_until_date ? (
                                                    <>
                                                        <div>Тест до</div>
                                                        <div>{moment(item.trial_period_until_date).format('DD.MM.YYYY')}</div>
                                                    </>
                                                ) : null}
                                                <span style={{ color: 'red' }}>Заблокировано</span>
                                            </>
                                    )) : null}
                            </Link>
                        )}
                    </Table.Cell>
                </Table.Row>
            )})
    ) : (
        <Table.Row><Table.Cell className="table-box__td_empty" colSpan={10}>Ничего не найдено.</Table.Cell></Table.Row>
    );

    const handleSubmit = (value: string) => {
        setSearchQuery(value)
        setActivePage(1);
    }

    const handleDelete = async () => {
        setOpenModal(false)
        await apiRequest(`/accounts?ids=${selectedIds.join(",")}`, navigate, 'DELETE');
        setSelectedIds([])
        setDataVersion(dataVersion + 1);
    }

    const openDeleteModal = () => {
        setOpenModal(true)
    }

    const perPageOptions = [
        { key: '10', text: 'Показывать по 10', value: 10 },
        { key: '20', text: 'Показывать по 20', value: 20 },
        { key: '50', text: 'Показывать по 50', value: 50 },
        { key: '100', text: 'Показывать по 100', value: 100 },
    ];

    const handlePerPageChange = (event: React.SyntheticEvent<HTMLElement>, data: any) => {
        setPerPage(data.value);
        setActivePage(1);
    };

    const handlePageChange = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
        setActivePage(data.activePage);
    };

    const handleSelectChangeCheckbox = (event: React.SyntheticEvent, data: any) => {
        const { value } = data;
        const isChecked = data.checked;
        if (isChecked) {
            setSelectedPartnerFilterOptions([...selectedPartnerFilterOptions, value]);
            setPartnerFilterOption(
                partnerFilterOption.map((option) => {
                    if (option.value === value) {
                        option.checked = true;
                    }
                    return option;
                })
            );
        } else {
            setSelectedPartnerFilterOptions(selectedPartnerFilterOptions.filter((v) => v !== value));
            setPartnerFilterOption(
                partnerFilterOption.map((option) => {
                    if (option.value === value) {
                        option.checked = false;
                    }
                    return option;
                })
            );
        }
    };
    
    const dropdownOptions = partnerFilterOption.map(({ key, text, value, checked }) => (
        <Dropdown.Item key={key}>
            <Checkbox
                label={text}
                value={value}
                checked={checked}
                onChange={handleSelectChangeCheckbox}
            />
        </Dropdown.Item>
    ));

    return (
        <>
            <Container fluid className='main-box__container-fluid'>
                <Grid columns={2} className='main-box__nomargin'>
                    <Aside></Aside>
                    <Grid.Column className='grid-box__right'>
                        <div className="grid-box__header">
                            <Header as='h1' className='main-box__title' id='header-block'>Клиенты</Header>
                            <LogoutButton />
                        </div>
                        
                        <Container fluid className='main-box__content'>
                            <Grid className="grid-box__area" id='actions-block'>
                                <Grid.Column width={10} className="grid-box__area">
                                    {isAdmin && (
                                        <Dropdown
                                            placeholder={selectedPartnerFilterOptions.length > 0 ? `Партнеры (${selectedPartnerFilterOptions.length})` : "Все партнеры"}
                                            fluid
                                            className={`form-box__multiselect grid-box__action-btn ${
                                                selectedPartnerFilterOptions.length > 0 ? 'form-box__multiselect_selected' : ''
                                            }`}
                                            multiple
                                            selection
                                            onChange={handleSelectChangeCheckbox}
                                            icon={<Icon name='angle down' />}
                                        >
                                            <Dropdown.Menu>{dropdownOptions}</Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                    
                                    <Link 
                                        to="/accounts/form" 
                                        className='grid-box__action-btn btn-box btn-box_primary btn-box_icon btn-box_fill btn-box_icon-bg'
                                    >
                                        <div className='btn-box_icon-bg-area'>
                                            <svg className='btn-box_icon-plus'><use xlinkHref={`${sprite}#plus`}></use></svg>
                                        </div>
                                        <span>Добавить</span>
                                    </Link>
                                    
                                    {isAdmin && (
                                        <Button 
                                            disabled={selectedIds.length <= 0}
                                            onClick={openDeleteModal}
                                            className="grid-box__action-btn btn-box btn-box_simple btn-box_icon btn-box_primary-color"
                                        >
                                            <div className='btn-box_simple-icon'>
                                                <svg className='btn-box_icon-trash'><use xlinkHref={`${sprite}#trash`}></use></svg>
                                            </div>
                                            <span>Удалить выбранные ({selectedIds.length})</span>
                                        </Button>
                                    )}
                                </Grid.Column>
                                <Grid.Column className='grid-box__search'>
                                    <Form className='grid-box__action-search'>
                                        <Input 
                                            icon="search"
                                            placeholder='Поиск...' 
                                            onChange={(event) => handleSubmit(event.target.value)} 
                                        />
                                    </Form>
                                </Grid.Column>
                            </Grid>
                            <div className='grid-box__space'>
                                <Table celled className='table-box'>
                                    <Table.Header>
                                        <Table.Row>
                                            {tableHead}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {tableRows}
                                    </Table.Body>
                                </Table>
                            </div>
                            {loading && 'Загрузка...'}
                        </Container>
                        <Container fluid id="pagination-block">
                            <Grid.Row className='pagination-box'>
                                <Grid.Column className='pagination-box__col'>
                                    <Dropdown
                                        options={perPageOptions}
                                        text={`Показывать по ${perPage}`}
                                        value={perPage}
                                        onChange={handlePerPageChange}
                                        className="pagination-box__dropdown"
                                    />
                                    {totalPages > 0 && (
                                        <Pagination
                                            totalPages={totalPages}
                                            activePage={activePage}
                                            onPageChange={handlePageChange}
                                            className="pagination-box__pages"
                                            prevItem={{ content: <svg className='btn-box_icon-left-arrow'><use xlinkHref={`${sprite}#left-arrow`}></use></svg> }}
                                            nextItem={{ content: <svg className='btn-box_icon-right-arrow'><use xlinkHref={`${sprite}#right-arrow`}></use></svg> }}
                                        />
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Container>
                    </Grid.Column>
                </Grid>
            </Container>
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Modal.Header>Вы уверены что хотите удалить клиента?</Modal.Header>
                <Modal.Content>
                    Удалить?
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleDelete}>Да</Button>
                    <Button onClick={() => setOpenModal(false)}>Нет</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ListAccounts;